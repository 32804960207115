import React from "react";

import axios from "axios";

import "./App.css";

export default function App() {
  let [state, setState] = React.useState({
    message: "",
    success: "",
  });
  return (
    <div className="body">
      <div className="title-wrapper">
        <div className="h1-wrapper">
          <h1 className="title">CASTUS Dealer Registration</h1>
        </div>
      </div>

      <div className="App">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col">
            <div className="login-body">
              <div className="form-group text-center">
                <p className="login-title">Register</p>
              </div>
              <div
                className="form-group"
                style={{ display: state.success.length > 0 ? "none" : "" }}
              >
                <input
                  placeholder="Email"
                  className="form-control"
                  type="email"
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{ display: state.success.length > 0 ? "none" : "" }}
              >
                <input
                  placeholder="Password"
                  className="form-control"
                  type="password"
                  onChange={(e) => {
                    setState({ ...state, password: e.target.value });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{ display: state.success.length > 0 ? "none" : "" }}
              >
                <input
                  placeholder="Confirm Password"
                  className="form-control"
                  type="password"
                  onChange={(e) => {
                    setState({ ...state, confirmPassword: e.target.value });
                  }}
                />
              </div>
              <div
                className="form-group"
                style={{ display: state.success.length > 0 ? "none" : "" }}
              >
                <button
                  className="btn btn-block btn-primary"
                  onClick={() => {
                    if (state.password === state.confirmPassword)
                      axios
                        .post(
                          "https://ah7idic4he.execute-api.us-west-2.amazonaws.com/default/auth/register",
                          { email: state.email, password: state.password }
                        )
                        .then((res) => {
                          if (res.data.success) {
                            setState({
                              ...state,
                              success:
                                "You have successfully registered! Click here to continue to the Dealer Portal.",
                            });
                          } else {
                            if (
                              res.data.message &&
                              res.data.message.errmsg &&
                              res.data.message.errmsg.includes("duplicate")
                            ) {
                              setState({
                                ...state,
                                message:
                                  "This email has already been used to register an account.",
                              });
                            } else if (
                              res.data.message &&
                              res.data.message.errors &&
                              res.data.message.errors.email
                            ) {
                              setState({
                                ...state,
                                message: "Please enter valid email",
                              });
                            } else if (
                              res.data.message &&
                              res.data.message.errors &&
                              res.data.message.errors.password
                            ) {
                              setState({
                                ...state,
                                message:
                                  "Your password must be at least 8 characters long",
                              });
                            }
                          }
                          console.log(res.data);
                        });
                    else
                      setState({ ...state, message: "Passwords must match" });
                  }}
                >
                  Submit
                </button>
              </div>

              <div className="form-group">
                <div
                  className="alert alert-danger"
                  style={{ display: state.message.length > 0 ? "" : "none" }}
                  onClick={() => {
                    setState({ ...state, message: "" });
                  }}
                >
                  {state.message}
                </div>
                <div
                  className="alert alert-success"
                  style={{ display: state.success.length > 0 ? "" : "none" }}
                  onClick={() => {
                    window.open("https://dealers.castus.tv");
                  }}
                >
                  {state.success}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>

      <div
        className="text-center footer"
        style={{ width: "100%", fontSize: ".75rem" }}
      >
        ∙ CASTUS Corp ∙ 165 NE Juniper Street Suite 200 Issaquah, WA 98027 ∙
        Call us at 800.215.3880 ∙ castus@castus.tv ∙
      </div>
    </div>
  );
}
